// -------------------------------------------
// Google Tag Manager
// -------------------------------------------

var dataLayer = [];

if (window.googleTagManagerContainerId) {
    var googleTagManagerContainerId = window.googleTagManagerContainerId;
    var googleTagManagerRoleInfo = window.googleTagManagerRoleInfo;
    var googleTagManagerPersonId = window.googleTagManagerPersonId;

    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', googleTagManagerContainerId);

    // Prepare Data to send to GTM 
    dataLayer.push({
        'PersonId': googleTagManagerPersonId,
        'Groups': googleTagManagerRoleInfo
    });
    // Once Data is ready trigger "variablesSet" event that GTM is listening for.
    dataLayer.push({ 'event': 'variablesSet' });
}